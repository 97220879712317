<template>
  <div class="container mb-3 p-3">
    <div class="card">
      <div class="p-2">
        <div v-if="listObject.length > 0">
          <div class="content-between mb-2">
            <div><small>Booking Detail / วันและเวลาที่นัดหมาย</small></div>
            <div><small>สถานะ</small></div>
          </div>
          <div
            v-for="booking of listObject"
            :key="booking"
            @click="$router.push(`/booking/detail/${booking.id}`)"
          >
            <div class="card mb-2 bg-whitesmoke shadow-sm">
              <div class="card-body booking-detail-list">
                <div class="row">
                  <div class="col-6" style="color: var(--primary-color)">
                    {{ booking.booking_name }} #{{ booking.prefix_id }}
                  </div>
                  <div class="col-6 text-right" :class="colorClass(booking)">
                    {{ booking.status_name }}
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <small>{{ booking.booking_date | momentFormat() }}</small>
                  </div>
                  <div class="col-6 text-right">
                    <u><small>ดูรายละเอียด</small></u>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="text-center"><small>ไม่มีข้อมูลการจอง</small></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      listObject: []
    };
  },
  created() {
    this.getList();
  },
  filters: {
    momentFormat(val) {
      return moment(val).format("DD/MM/YYYY (HH:mm)");
    }
  },
  methods: {
    async getList() {
      const res = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/Booking/getMenulist`
      );

      this.listObject = res.detail;
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    }
  }
};
</script>

<style>
.bg-whitesmoke {
  background-color: whitesmoke;
}
.booking-detail-list {
  padding: 5px 10px;
  font-size: var(--text-md);
}
</style>
